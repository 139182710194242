import { useLDClient } from 'launchdarkly-react-client-sdk';
import { FEATURE_FLAGS } from '../config/launchdarkly';

type AllowedValues = (typeof FEATURE_FLAGS)[keyof typeof FEATURE_FLAGS];

export function useFeatureFlag(
  name: AllowedValues,
  defaultValue: boolean = false
): boolean {
  const ldClient = useLDClient();
  return ldClient?.variation(name, defaultValue);
}
