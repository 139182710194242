import { useCallback, useEffect, useMemo } from 'react';
import authStore from '../stores/auth.store';
import { ZendeskHelpers } from '../utilities/zendeskHelpers';
import { RoutePaths } from '../constants/routes';

export const useZendesk = (location?: string) => {
  const email = authStore?.authInfo?.preferred_username;
  const name = authStore?.authInfo?.name;

  const baseOptions: any = useMemo(() => {
    return {
      zIndex: 50,
      position: { horizontal: 'right', vertical: 'top' },
      offset: {
        horizontal: '50px',
        vertical: '-5px',
      },
      color: {
        theme: '#FFF',
        launcherText: '#0078D8',
        launcher: '#FFF',
      },
    };
  }, []);

  // only for schedule for now
  if (location === RoutePaths.Schedule) {
    baseOptions.offset.vertical = '0px';
    baseOptions.color.launcher = '#F6F8FA';
  }

  if (location?.includes(RoutePaths.AIClinicalNote)) {
    baseOptions.offset.vertical = '-8.5px';
    baseOptions.color.launcher = '#F6F8FA';
    baseOptions.offset.horizontal = '60px';
  }

  useEffect(() => {
    return () => {
      ZendeskHelpers.applyZendeskChanges('webWidget', 'logout');
    };
  }, []);

  const updateZendesk = useCallback(async () => {
    const zendeskIframe = (await ZendeskHelpers.waitForElement(
      document,
      '[id="launcher"]'
    )) as any;
    const zendeskDocument = (zendeskIframe?.contentDocument ||
      zendeskIframe?.contentWindow?.document) as Document | null;
    if (email && name) {
      ZendeskHelpers.applyZendeskChanges('webWidget', 'prefill', {
        name: {
          value: name,
          readOnly: false,
        },
        email: {
          value: email,
          readOnly: false,
        },
      });
      ZendeskHelpers.applyZendeskChanges('webWidget', 'updateSettings', {
        webWidget: baseOptions,
      });
      await ZendeskHelpers.moveZendeskToHeaderPosition(zendeskDocument);
    } else {
      await ZendeskHelpers.resetZendeskWidgetButton(zendeskDocument);
    }
  }, [baseOptions, email, name]);

  useEffect(() => {
    setTimeout(updateZendesk, 1000);
  }, [updateZendesk, email, name]);
};
