export type CapellaScreenType = 'MyQ' | 'Scheduler';

const typeToCapellaUrl = {
  MyQ: () => `frmMyQueueNew.aspx?IsDirectUrl=Y`,
  Scheduler: () => `frmAppointments.aspx?hdnSourceScreen=Menu&IsDirectUrl=Y`,
};

export function getCapellaURL(type: CapellaScreenType | null, baseUrl: string) {
  if (!type) {
    return '';
  }

  const capellaScreenUrl = typeToCapellaUrl[type]();

  return `${baseUrl}/${capellaScreenUrl}`;
}
